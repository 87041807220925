export default {
  'task.title': '任务',
  'task.annotation': '标注',
  'task.audit': '审核',
  'task.card.remain': '剩余 {count} 题',
  'task.card.finish': '我已答 {count} 题',
  'task.card.other': '当前任务已无更多题，请选择其他任务卡片',
  'task.card.end': '已结束',
  'task.detail.upload': '上传附件',
  'task.detail.upload.error1': '文件格式错误',
  'task.detail.upload.error2': '文件大小请控制在50以内M',
  'task.detail.upload.desc':
    ' 支持格式：图片（png、jpg、jpeg、gif）、视频（mp4、mov）、音频（mp3）；单个文件大小不超过50M',
  'task.detail.submit': '提交',
  'task.detail.skip': '跳过',
  'task.detail.cancel': '退出',
  'task.detail.cancel.desc': '直接退出，当前输入的内容将不做保持',
  'task.detail.label.result': '标注结果',
  'task.detail.problematical': '此题存在问题，无法作答',
  'task.detail.kind.tips': '温馨提示',
  'task.detail.time.left': '距结束还剩',
  'task.detail.time.left.desc': '超时未提交，题目将自动回收，已填写内容不做保存',
  'task.detail.timeout.desc': '当前题目超时未完成，已自动回收。请认领其他题目继续答题～',
  'task.detail.error.required': '有必填项未填写',
  'task.detail.audit.title': '对左侧作答进行评判',
  'task.detail.audit.root.id': '源题ID',
  'task.detail.audit.question': '题目',
  'task.detail.audit.question.id': '题目ID',
  'task.detail.audit.annotator': '标注员',
  'task.detail.audit.auditor': '审核员',
  'task.detail.audit.hint': '判断左侧标注结果是否正确，且符合要求',
  'task.detail.audit.quicker': '快捷键：是 - Alt+1；否 - Alt+2',
  'task.detail.save.desc': '如题目超时回收或点击“跳过、退出”，保存结果将自动清空；请及时提交',

  'task.custom.question.title': '自定义题目范围',
  'task.custom.question.desc': '请输入题目ID （字段：Data_id）一个题目ID一行',
  'task.custom.question.placeholder': '一个题目ID一行',
  'task.custom.root.title': '自定义源题范围',
  'task.custom.root.desc': '请输入源题ID（字段：Questionnaire_id）一个源题ID一行',
  'task.custom.root.placeholder': '一个源题ID一行',
  'task.diff.hint': '没有更改的内容',
  'task.diff.title': '内容对比',
  'task.diff.shortcut.key': '快捷键：展开/收起',
  'task.diff.benchmark': '基准',
  'task.diff.contrast': '对比',

  'task.question.all': '全部题目',
  'task.question.all1': '已答题目',
  'task.question.all2': '已审题目',
  'task.question.question': '仅看标为有问题',
  'task.question.scope': '自定义题目范围',
  'task.question.placeholder': '输入您想要查询的源题ID（Questionnaire_id），按回车键搜索',
  'task.question.placeholder2': '输入您想要查询的题目ID（Data_id），按回车键搜索',
  'task.question.switch.sub': '切换子题',
  'task.question.switch.sub.placeholder': '选择题目 ID',

  'task.plugin': '插件',
  'task.plugin.title1': '针对提示',
  'task.plugin.title2': '针对对话',
  'task.plugin.title3': '针对对话中的提问',
  'task.plugin.google': 'Google 翻译',
  'task.plugin.deepl': 'Deepl 翻译',
  'task.plugin.grammar.check': '语法校验',

  'task.error.msg1': '没有标为有问题的题目',
  'task.error.msg2': '没有更多题目了',
  'task.error.msg3': '错误处/总字符数',
  'task.error.msg4': '翻译服务暂时不可用',
  'task.hint1': '未达标题数为 0',
  'task.hint2': '未采纳题数为 0',
  'task.hint3': '仅看未达标',
  'task.hint4': '仅看未采纳',
  'task.hint5': '源题组合查看',
  'task.hint6': '此题被标记为：存在问题，无法作答',
  'task.hint7': '请输入用户名',

  'member.title': '成员管理',
  'member.del.desc': '是否确定将此用户移除此团队',
  'member.invite': '邀请成员',
  'member.team': '团队',
  'member.team.join': '邀请你加入团队',
  'member.team.join.success': '你已加入，请前往标注页面',
  'member.link.timeout': '链接已超时',
  'member.join': '加入',
  'member.hint': '温馨提示：如某用户已加入某团队，再加入新团队，将自动退出之前的团队',
  'member.operate.hint': '操作方式：将链接发给成员，成员点击链接通过邀请，加入后默认为普通成员',
  'member.copy.url': '复制链接',
  'member.regenerate': '重新生成',
  'member.validity': '有效期至',

  'common.copy': '复制',
  'common.copy.success': '复制成功',
  'common.save.success': '保存成功',
  'common.none': '无',
  'common.username': '用户名',
  'common.username.id': '用户 ID',
  'common.role': '角色',
  'common.operate': '操作',
  'common.save.placeholder': '保存成功',
  'common.preview': '预览',
  'common.check': '查看',
  'common.check.single': '单题查看模式',
  'common.switch': '换一题',
  'common.prev': '上一题',
  'common.next': '下一题',
  'common.translation': '译文',
  'common.del': '移除',
  'common.sure': '确定',
  'common.save': '保存',
  'common.cancel': '取消',
  'common.search': '搜索',
  'common.personal.center': '个人中心',
  'common.quit': '退出登录',
  'common.sort': '排序',
  'member.super.admin': '超级管理员',
  'member.admin': '管理员',
  'member.regular': '普通用户',
  // 任务管理 v1.7
  'task.menu.name': '我的任务',
  'task.manage.title': '任务管理',
  'task.manage.name': '任务名称',
  'task.manage.status': '任务状态',
  'task.manage.total.task': '总答题数',
  'task.manage.total.task.user': '答题数',
  'task.manage.total.audit': '总审核数',
  'task.manage.remain': '剩余题数',
  'task.manage.total.question.not.rate': '未达标率',
  'task.manage.remain.desc':
    '剩余题数：一题如要求答题多次，算多题， 但每题每人只可领取1次，故每人看到的剩余题数不一致。这里为总的剩余题数',
  'task.manage.create.time': '创建时间',
  'task.manage.detail.id': '任务 ID',
  'task.manage.detail.title': '任务信息',
  'task.manage.detail.stage': '阶段',
  'task.manage.detail.stages': '{count} 审',
  'task.manage.detail.progress': '进度总览',
  'task.manage.detail.status': '任务状态',
  'task.manage.detail.status.done': '已结束',
  'task.manage.detail.status.open': '进行中',
  'task.manage.detail.task.desc': '任务描述',
  'task.manage.total.question.not.reach': '未达标题数',
  'task.manage.total.question.not.reach2': '未采纳题数',
  'task.manage.total.question.not.reach2.desc':
    '假设一道题需要3轮审核，另外2人判断为达标，此用户判断为未达标，则此题的审题结果是未被采纳',
  'task.manage.total.question.not.desc': '关联审核任务中审核未达标或运营手动打回',
  'task.manage.total.question.remain': '剩余题数',
  'task.manage.total.question.remain.desc':
    '剩余题数：一题如要求答题多次，算多题， 但每题每人只可领取1次，故每人看到的剩余 题数不一致。这里为总的剩余题数',
  'task.manage.total.audit.count': '总审题数',
  'task.manage.total.audit.count.user': '审题数',
  'task.manage.total.audit.approved': '审核通过',
  'task.manage.total.audit.not.approved': '审核未通过',
};
